<template>
    <div>
        <v-dialog
            v-model="successDialog"
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    {{ $t('cardAccepted') }}
                </v-card-title>
                <v-card-text>
                        {{ $t('cardAcceptedText') }}                   
                    
                    <br>
                    <v-col
                        class="text-center"
                    >
                        <v-icon
                            size="100"
                            color="green"
                        >
                            mdi-check-circle
                        </v-icon>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="justify-center text-center"
                        :loading="working"
                        @click="successDialog = false; CreateCreditCardList()"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="errorDialog"
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    {{ $t('payment-error') }}
                </v-card-title>
                <v-card-text>
                        {{ $t('payment-error2') }}
                        
                    <br>
                    <v-col
                        class="text-center"
                    >
                        <v-icon
                            size="100"
                            color="red"
                        >
                            mdi-close-circle
                        </v-icon>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="justify-center text-center"
                        :loading="working"
                        @click="errorDialog = false; CreateCreditCardList()"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-alert
            v-if="creditCardItems.length === 0 && !working"
            color="red"
        >
            <span
                class="white--text"
            >
                <b> {{ $t('creditcardList-infoBox') }}</b>
            </span>
        </v-alert>
        <v-card>
            <v-card-title class="py-1">
                {{ $t('creditcardList-title') }}
                <v-spacer />
                <v-btn
                    v-if="creditCardItems.length === 0"
                    color="primary"
                    @click="openAddCreditCardDialog()"
                >
                {{ $t('creditcardList-addCreditCard') }}
                </v-btn>    

            </v-card-title>                               

            <v-card-text>
                <template v-if="creditCardItems.length > 0">
                    <v-list-item
                        v-for="card in creditCardItems"
                        :key="card.id"
                        two-line
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ $t('shop-payment-type-1') }}

                                 
                            </v-list-item-title>       
                   
                            <v-list-item-subtitle>
                                {{ card.maskedPan }}
                            </v-list-item-subtitle>  
                            <v-list-item-subtitle />
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row>
                                <v-btn
                                    v-if="cardIsExpired(card.expiryDate)"
                                    class="mr-1"
                                    color="red"
                                    style="color:white; height:40px; cursor: default;"
                                    max-width="250"
                                >
                                    <b> {{ $t('creditcardList-expired') }}</b>
                                </v-btn>
                                <v-btn @click="clickOpenUpdateCreditCardDialog(card.id)">
                                    {{ $t('myuser/changemypassword-updatebutton') }}
                                </v-btn>
                                <v-btn
                                    icon
                                    @click.stop="clickDeleteCreditCardDialog(card.id)"
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </template>
                <template v-else-if="working">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('loadingList') }}</v-list-item-title>                                
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-else>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('creditcardList-noCreditCards') }}</v-list-item-title>        

                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-dialog
                    v-model="deleteCreditCardDialog"
                    width="500"
                >  
                    <v-card>
                        <v-card-title class="py-1">
                            {{ $t('creditcardList-removeCreditcard') }}
                        </v-card-title>

                        <v-card-text>
                            <br>
                            {{ $t('creditcardList-removeCreditcard-info') }}
                            <br>
                            <br>
                            {{ $t('creditcardList-removeCreditcard-info2') }}
                            <br>
                            <br>
                            {{ $t('creditcardList-removeCreditcard-info3') }}
                            <br>
                            <br>
                            {{ $t('creditcardList-removeCreditcard-info4') }}
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                            <v-btn
                                @click="clickCloseDeleteCreditCardDialog"
                            >
                                {{ $t('string-no') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn
                                :disabled="working"
                                :loading="working"
                                @click="clickConfirmDeleteCreditCardDialog"
                            >
                                {{ $t('string-yes') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                
            </v-card-text>
            <v-card-actions>
                <v-spacer />

                <v-dialog
                    v-model="creditCardDialog"
                    width="500"
                >
                    <v-card>
                        <v-card-title class="py-1">
                            {{ $t('creditcardList-addCreditCard') }}
                        </v-card-title>

                        <v-card-text>
                            <br>
                            {{ $t('creditcardList-addCreditCard-info') }}
                            <br>
                            {{ $t('creditcardList-updateCreditcard-info2') }}
                            <br>
                            <br>
                            {{ $t('creditcardList-updateCreditcard-info3') }}
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>                                    
                            <v-btn
                                @click="ClickCloseCreditCardDialog"
                            >
                                {{ $t('registrations-openLocationbutton-close') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn
                                :href="addCreditCardLink"
                                @click="ClickAddCreditCard"
                            >
                                {{ $t('registrations-addbutton') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="updateCreditCardDialog"
                    width="500"
                >
                    <v-card>
                        <v-card-title class="py-1">
                            {{ $t('creditcardList-updateCreditCard') }}
                        </v-card-title>

                        <v-card-text>
                            <br>
                            {{ $t('creditcardList-updateCreditcard-info') }}
                            <br>
                            <br>
                            {{ $t('creditcardList-updateCreditCard-automatic') }}
                            <br>
                            <br>
                            {{ $t('creditcardList-updateCreditcard-info2') }}                      ¨
                            <br>
                            <br>
                            {{ $t('creditcardList-updateCreditcard-info3') }}
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>                                    
                            <v-btn
                                @click="ClickCloseCreditCardDialog"
                            >
                                {{ $t('buttonClose') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn
                                :href="updateCreditCardLink"
                                @click="ClickAddCreditCard"
                            >
                                {{ $t('customers/customerlist-customerDatabase-refreshButtonHoover') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            creditCardDialog: false,
            deleteCreditCardDialog: false,
            selectedDCreditCardIdToDelete: null,
            creditCardItems: [],
            addCreditCardLink: null,
            updateCreditCardDialog: false,
            updateCreditCardLink: null,

            creditCardAddClicked: false,
            working: false,
            successDialog: false,
            errorDialog: false

        }
    },
    computed: {    
        ...mapGetters({
            getEmailConfirmed: 'getEmailConfirmed',
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber'
        })
    },
    beforeMount(){
        document.addEventListener('visibilitychange', this.BrowerTabActive)
        
    },
    beforeDestroy(){
        document.removeEventListener('visibilitychange', this.BrowerTabActive)
    },
    created() {
        if(this.$route.query.cardaccepted){
            this.working = true
            if(this.$route.query.cardaccepted === 'true'){
                this.successDialog = true
            }
            else if(this.$route.query.cardaccepted === 'false')
            {
                this.errorDialog = true
            }
        }
        this.CreateCreditCardList()
    },
    methods: {
        CreateCreditCardList(){
            this.working = true

            //NOTE: This is the only time we set the selected pnumber to the global one in this component
            //The rest of the time we use the selected p number from the local p number selector because this is the one who
            //determines where the relation should be attached
            this.selectedPNumber = this.getSelectedPNumber
            
            Promise.all([
                this.FetchUserCreditCardList({pNumber: this.selectedPNumber})
            ])
                .then(results => {
                    this.creditCardItems = results[0].customerCreditCardList
                    this.working = false
                })
        },
        BrowerTabActive() {
            //document.title = document.hidden ? "I'm away" : "I'm here"
            if(this.creditCardDialog && this.creditCardAddClicked || this.updateCreditCardDialog && this.creditCardAddClicked){
                this.CreateCreditCardList()
                /*
                this.FetchUserCreditCardList()
                    .then(result => {
                        this.creditCardList = result.customerCreditCardList
                    })
                    */
            }
        },
        ClickCloseCreditCardDialog(){
            this.$emit('creditCardChanged')
            this.CreateCreditCardList()
            this.creditCardAddClicked = false
            this.updateCreditCardDialog = false
            this.creditCardDialog = false
        },
        openAddCreditCardDialog(){
            this.creditCardDialog = true
            this.FetchAddCreditCard({
                pNumber: this.getSelectedPNumber
            })
                .then(result => {
                    this.addCreditCardLink = result
                })
        },
        ClickAddCreditCard() {
            this.creditCardAddClicked = true
            
        },
        clickConfirmDeleteCreditCardDialog(){
            this.working = true
            this.FetchDeleteCreditCard({
                creditCardId: this.selectedDCreditCardIdToDelete,
                pNumber: this.getSelectedPNumber
            })
                .then(() => {
                    this.FetchUserCreditCardList({pNumber: this.getSelectedPNumber})
                        .then(result => {
                            this.creditCardList = result.customerCreditCardList
                        })             
                })
                .then(() => {
                    this.$emit('creditCardChanged')
                    this.clickCloseDeleteCreditCardDialog()
                    this.CreateCreditCardList()
                    this.working = false
                })
        },
        clickOpenUpdateCreditCardDialog(id){
            this.updateCreditCardDialog = true
            this.FetchUpdateCreditCard({
                pNumber: this.getSelectedPNumber,
                creditCardToReplace: id
            })
                .then(result => {
                    this.updateCreditCardLink = result
                })
        },
        cardIsExpired(expiryDate){
            expiryDate = expiryDate.toString()

            let expiryYear = expiryDate.substring(0, 2)
            let expiryMonth = expiryDate.substring(2, 4)
            expiryYear = '20' + expiryYear

            let expiryDateObject = new Date(expiryYear, expiryMonth - 1, 1)
            let today = new Date()

            if(expiryDateObject < today){
                return true
            }
            return false
        },
        clickDeleteCreditCardDialog(id){
            this.selectedDCreditCardIdToDelete = id
            this.deleteCreditCardDialog = true
        },
        clickCloseDeleteCreditCardDialog(){
            this.deleteCreditCardDialog = false
            this.selectedDCreditCardIdToDelete = null
        },
        ...mapActions({
            FetchUserCreditCardList: 'FetchUserCreditCardList',
            FetchAddCreditCard: 'FetchAddCreditCard',
            FetchDeleteCreditCard: 'FetchDeleteCreditCard',
            FetchUpdateCreditCard: 'FetchUpdateCreditCard'
        })
    }

    
}
</script>